.hero {

  background-size: cover;

  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.hero-content {
  text-align: center;
  max-width: 80%;

}

.hero-content h1 {
  font-size: 3rem;
}

.hero-content p {
    font-size: 1.5rem;
    font-weight: 900;
}


.main-topic{
 background-color: #ff5722;
 padding: 15px;
 border-radius: 5px;
}

*,
*:after,
*:before {
	box-sizing: border-box;
}

:root {
  --x: 0;
  --y: 0;
  --xp: 0;
  --yp: 0;
	--hue: calc(0 + (var(--xp) * 500));
	--bg: hsl(0 0% 10%);
	--size: 100px;
	--glow: radial-gradient(
			50% 50% at center,
			hsl(var(--hue) 80% 85%),
			hsl(var(--hue) 80% 70%),
			transparent
		)
		calc((var(--x) * 1px) - (var(--size) * 0.5))
		calc((var(--y) * 1px) - (var(--size) * 0.5)) / var(--size) var(--size) no-repeat fixed;
}

.controls {
  position: fixed;
  top: 2rem;
  right: 2rem;
}

body {
	display: flex;
	place-items: center;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: center;
	min-height: 100vh;
	font-family:  "SF Pro Text", "SF Pro Icons", "AOS Icons", "Helvetica Neue", Helvetica, Arial, sans-serif, system-ui;
	background: var(--bg);
}

.explore-button {
	border-radius: 1rem;
	text-transform: uppercase;
	font-weight: bold;
	letter-spacing: 0.1ch;
	background: var(--bg);
	border: 4px solid transparent;
	box-shadow: 0 1px hsl(0 0% 100% / 0.15) inset;
	cursor: pointer;
	background: linear-gradient(hsl(0deg 31.22% 89.47%), hsl(0deg 66.79% 83.88%)) padding-box, var(--glow), linear-gradient(rgb(246, 245, 245), rgb(156, 232, 251)) border-box;
	transition: background-size 0.24s;
  touch-action: none;
  position: relative;
	padding: 1rem 2rem;
}

.explore-button ::before {
  content: "";
  position: absolute;
  inset: 0;
  box-shadow: 0 1px hsl(0 0% 100% / 0.15) inset;
  background: var(--bg);
  z-index: 2;
/*   border: 4px solid transparent; */
  border-radius: 1rem;
}

.explore-button  span {
	background: var(--glow), black;
	background-clip: text;
	color: transparent;
  height: 100%;
  width: 100%;
  z-index: 2;
  position: relative;
  inset: 0;
}

:root:has(button:active) {
	--size: 300px;
}

.explore-button ::after {
  content: "";
  position: absolute;
  inset: -4px;
  filter: blur(20px);
  border: 4px solid transparent;
  background: var(--glow);
  border-radius: 1rem;
}

.gradient-text-container {
    position: relative;
    display: inline-block; /* Adjust the size based on the content */
    padding: 10px 20px; /* Add some padding for spacing */
    margin: 20px 0; /* Add some margin for spacing */
    text-align: center; /* Center align the text */
    background-color: #ffffff; /* Set the background to black */
    border-radius: 10px; /* Optional: Add border-radius for rounded corners */
}

.gradient-text {
    font-size: 1.5rem; /* Adjust size as needed */
    background: linear-gradient(45deg, #ff6b6b, #feca57, #1dd1a1, #5f27cd);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}