.property-card {
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  width: 300px;
  text-align: center;
  transition: transform 0.3s ease;
}

.property-card:hover {
  transform: scale(1.05);
}

.property-card img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.property-card h3 {
  margin: 10px 0;
}

/* .property-card a {
  display: block;
  padding: 10px;
  background: #ff5722;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
} */

.details-button{
    justify-content: center;
    display: flex;
}