.home {
  position: relative;
  height: 100vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  overflow: auto;
}

.background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.home-content {
  position: relative;
  z-index: 1;
  text-align: center;
  padding: 20px;
}

.animated-text h1 {
  font-size: 3rem;
  margin-bottom: 20px;
  animation: fadeIn 3s ease-in-out;
}

.animated-text p {
  font-size: 1.5rem;
  margin-bottom: 30px;
  animation: slideIn 3s ease-in-out;
}



@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }


}
.topic{
    color: #fefcfc;
}