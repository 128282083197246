@import url('https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900&display=swap');

.navbar {
  background: #333;
  color: #fff;
  padding: 10px;
}

.navbar ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
}

.navbar li {
  margin: 0 15px;
}

.navbar a {
  color: #fff;
  text-decoration: none;
}


*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
}
body{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 100vh;
    gap: 30px;
    background: #1c2630;
}
.btn{
    position: relative;
    display: inline-block;
    padding: 10px 30px;
    font-size: 1em;
    background: transparent;
    border: 2px solid var(--clr);
    font-weight: 500;
    border-radius: 40px;
    cursor: pointer;
    color: var(--clr);
    letter-spacing: 0.1em;
    text-transform: uppercase;
    transition: 1s;
    overflow: hidden;
}
.btn:hover{
    background: var(--clr);
    box-shadow: 0 0 25px var(--clr),
    0 0 100px var(--clr),
    0 0 150px var(--clr);
}
.btn span{
    position: relative;
    display: inline-block;
    transition: transform 0.5s;
    text-shadow: 0 35px #fff;
    transform: translateY(0px);
}
.btn:hover span.hover{
    transform: translateY(-35px);
}