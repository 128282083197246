.property-detail {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.property-detail img {
  width: 100%;
  height: 400px;
  object-fit: cover;
}

.property-detail h1 {
  margin: 20px 0;
}

.property-detail p {
  margin: 10px 0;
}